<script setup>
  import SignInLayout from '@/layout/SignInLayout.vue'
</script>
<template>
  <SignInLayout>
    <template v-slot:right>
      <div class="login_page createAccount">
        <div class="sign_in_email">
          <!--          這裡要區分url顯示不同title-->
          <h2 v-if="!recommender">{{ $t('sign.createAnAccount') }}</h2>
          <h2 v-else>
            {{ $t('createAccount.referral') }}
          </h2>
          <div class="no_account">
            <p>{{ $t('createAccount.haveAccount') }}</p>
            &nbsp;&nbsp;
            <router-link to="/">
              {{ $t('sign.signIn') }}
            </router-link>
          </div>
          <h3>{{ $t('general.SignUpWithEmail') }}</h3>
          <div class="input_block">
            <label for="email_address">{{ $t('sign.emailAddress') }}*</label>
            <input v-model="email" type="email" id="email_address" name="email_address" ref="email">
          </div>
          <div class="input_name">
            <div class="fn">
              <label for="first_name">{{ $t('userProfile.name.firstName') }}*</label>
              <input v-model="firstName" type="text" id="first_name" name="first_name" ref="firstName">
            </div>
            <div class="ln">
              <label for="last_name">{{ $t('userProfile.name.lastName') }}*</label>
              <input v-model="lastName" type="text" id="last_name" name="last_name" ref="lastName">
            </div>
          </div>
          <div class="input_block">
            <div class="passwordFlex">
              <label for="password">{{ $t('sign.password.password') }}*</label>
              <input :type="showPassword===false?'password':'text'" v-model="password" id="password" name="password"
                     ref="password">
              <button @click="showPassword=!showPassword"
                      :class="showPassword===false?'passwordSH':'passwordSH active'"></button>
            </div>
          </div>
          <div class="input_block">
            <div class="passwordFlex">
              <label for="confirmPassword">{{ $t('resetPassword.verifyPassword') }}*</label>
              <input :type="showConfirmPassword===false?'password':'text'" v-model="confirmPassword"
                     id="confirmPassword" name="confirmPassword" ref="confirmPassword">
              <button @click="showConfirmPassword=!showConfirmPassword"
                      :class="showConfirmPassword===false?'passwordSH':'passwordSH active'"></button>
            </div>
          </div>
          <h4>{{ $t('userProfile.birthday.dateOfBirth') }}</h4>
          <div class="input_select">
            <div class="year">
              <label for="year">{{ $t('userProfile.birthday.year') }}</label>
              <select name="year" id="year" v-model="year" ref="year">
                <option v-for="year in years" :key="year" :value="year">{{ year }}</option>
              </select>
            </div>
            <div class="month">
              <label for="month">{{ $t('userProfile.birthday.month') }}</label>
              <select name="month" id="month" v-model="month" @onChange="createAccount" ref="month">
                <option v-for="(month, index) in months" :key="index" :value="index">{{ month }}</option>
              </select>
            </div>
            <div class="date">
              <label for="date">{{ $t('userProfile.birthday.date') }}</label>
              <select name="date" id="date" v-model="date" ref="date">
                <option v-for="date in dates" :key="date" :value="date">{{ date }}</option>
              </select>
            </div>

          </div>
          <div class="terms_and_policy">
            <input type="checkbox" id="age" v-model="ageAgreement">
            <label class="deleteConfirm" for="age">
              {{ $t('createAccount.age') }}
            </label>
            <input type="checkbox" id="news" v-model="receiveAgreement">
            <label class="deleteConfirm" for="news">
              {{ $t('createAccount.receiveAgreement') }}
            </label>
            <input type="checkbox" id="termsPrivacy" v-model="termsAgreement">
            <label class="deleteConfirm" for="termsPrivacy">
              {{ $t('createAccount.agreement') }}&nbsp;
              <a href="https://www.ipevo.com/terms-of-use" target="_blank">{{ $t('general.termsOfUse') }}</a>&nbsp;
              {{ $t('createAccount.and') }} &nbsp;
              <a href="https://www.ipevo.com/privacy-statement" target="_blank">{{ $t('general.privacyPolicy') }}</a>
            </label>
          </div>
          <div class="sign_in_end single">
            <a :class="termsAgreement&&ageAgreement ? 'continue' : 'continue disable'"
               @click="createAccount">{{ $t('general.createAccount') }}</a>
          </div>
        </div>
      </div>
    </template>
  </SignInLayout>
</template>
<script>
import { getFullYears, showAlertDialog, validEmail, validPassword, validString } from "ipevo_utils/dist";

export default {
  data () {
    return {
      //產品代碼
      productCode: null,
      //推薦人
      recommender: null,
      //核對密碼
      confirmPassword: null,
      //顯示核對密碼
      showConfirmPassword: false,
      //顯示密碼
      showPassword: false,
      //email
      email: null,
      //first name
      firstName: null,
      //last Name
      lastName: null,
      //password
      password: null,
      //天
      date: null,
      //月
      month: null,
      //年
      year: null,
      daysInMonth: null,
      //years
      years: [],
      //dates
      dates: [],
      //months
      months: [
        this.$t('month.january'),
        this.$t('month.february'),
        this.$t('month.march'),
        this.$t('month.april'),
        this.$t('month.may'),
        this.$t('month.june'),
        this.$t('month.july'),
        this.$t('month.august'),
        this.$t('month.september'),
        this.$t('month.october'),
        this.$t('month.november'),
        this.$t('month.december'),
      ],
      ageAgreement: false,
      termsAgreement: false,
      receiveAgreement: false
    }
  },
  watch: {
    year () {
      this.updateDaysInMonth()
    },
    month () {
      this.updateDaysInMonth()
    },
  },
  methods: {
    /**
     *  Init
     */
    init () {
      const date = new Date
      const currentYear = date.getFullYear()
      this.years = this.years = getFullYears()
      // 設定可選擇的日期
      this.year = currentYear
      this.month = date.getMonth()
      this.updateDaysInMonth()
    },
    /**
     * create account
     */
    createAccount () {
      // if(this.)
      //驗證email
      if (!validEmail(this.email)) {
        showAlertDialog(this.$t('errors.validEmail'))
        this.$refs.email.focus()
        return
      }

      //確認密碼是否有問題
      if (!validPassword(this.password)) {
        showAlertDialog(this.$t('errors.validPassword'))
        this.$refs.password.focus()
        return
      }

      //確認驗證密碼是否有問題
      if (!validPassword(this.confirmPassword)) {
        showAlertDialog(this.$t('errors.validPassword'))
        this.$refs.confirmPassword.focus()
        return
      }

      //檢驗密碼與檢驗密碼是否相同
      if (this.password !== this.confirmPassword) {
        showAlertDialog(this.$t('errors.validPasswordSame'))
        this.$refs.password.focus()
        return
      }

      //驗證年
      if (!this.year) {
        showAlertDialog(this.$t('errors.validYear'))
        this.$refs.year.focus()
        return
      }

      //驗證月
      if (!this.month && this.month < 0) {
        showAlertDialog(this.$t('errors.validMonth'))
        this.$refs.month.focus()
        return
      }

      //驗證月
      if (!this.date && this.date < 0) {
        showAlertDialog(this.$t('errors.validDate'))
        this.$refs.date.focus()
        return
      }

      //驗證名字
      if (!validString(this.firstName)) {
        showAlertDialog(this.$t('errors.validFirstName'))
        this.$refs.firstName.focus()
        return
      }

      //驗證姓
      if (!validString(this.lastName)) {
        showAlertDialog(this.$t('errors.validLastName'))
        this.$refs.lastName.focus()
        return
      }

      //verify receive checkbox
      // if(!this.receiveAgreement) {
      //   showAlertDialog('receiveAgreement checkout undone')
      //   return
      // }

      //verify termPrivacy checkbox
      if(!this.termsAgreement) {
        showAlertDialog('termsAgreement undone')
      }

      const time = new Date(this.year, this.month, this.date).getTime() / 1000
      const nameJson = JSON.stringify({
        firstName: this.firstName,
        lastName: this.lastName
      })
      this.$v1Api.register({
        email: this.email,
        password: this.password,
        registerType: 0,
        birthday: time,
        country: 0,
        gender: 0,
        occupation: 0,
        social: null,
        name: nameJson,
        recommender: this.recommender
      }).then(res => {
        if (res && !res.err) {
          this.$setLocalStorage('email', this.email)
        }
      })
    },
    /**
     * 取得當前月份的天數
     */
    updateDaysInMonth () {
      const year = this.year
      const month = this.month
      const date = new Date(year, month + 1, 0)
      this.daysInMonth = date.getDate()
      this.updateDates()
    },
    /**
     * 更新可選擇的日期
     */
    updateDates () {
      this.dates = []
      this.date = 1
      for (let date = 1; date <= this.daysInMonth; date++) {
        this.dates.push(date)
      }
      if (!this.dates.includes(this.date)) {
        this.date = null
      }
    }
  },
  beforeMount () {
    this.init()
  },
  // created () {
  //   const query = this.$route.query
  //   //確保參數不為空
  //   if (query.length !== 0) {
  //     //推薦人ID存在
  //     if (query.referral) {
  //       this.recommender = query.referral
  //     }
  //
  //     //產品代碼存在
  //     if (query.product) {
  //       this.productCode = query.product
  //     }
  //   }
  // }
}
</script>

<style scoped lang="scss">
.input_name, .input_select {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: space-between;
}

.input_select {
  margin-bottom: 77px;
}

.input_block {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
}

.ln, .fn, .month, .date, .year {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: calc((100% - 16px) / 2);
}

.month, .date, .year {
  width: calc((100% - 16px) / 3);
}

select {
  width: 100%;
  height: 30px;
  font-size: 14px;
  border-bottom: 1px solid #e0e0e0;
}

.terms_and_policy {
  margin-bottom: 25px;

  p {
    text-align: left;
    font-size: 12px;
    color: #646464;
    line-height: 16px;
  }

  a {
    text-decoration: underline;
    color: #74BA00;
  }
}
</style>
